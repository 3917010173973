<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :single-select="true"
        :single-select-value="'room'"
        item-key="room"
        locale="ru"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:[`item.dateRange`]="{ item }">
          {{ formatDate(item.start_date) }} - {{ formatDate(item.end_date) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="createAddTariff">
        Создать доп. тариф
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import toast from "../../../plugins/toast";
import { TariffsApiUrls } from "@/services/accommodationRequests/tariffs.api.js";

export default {
  name: "AdditionalTariffs",
  data: () => ({
    firstWatcherTrigger: false,
    totalItems: 0,
    options: {},
    items: [],
    loading: false,
    headers: [
      { text: "Тариф", value: "name", class: "table-title" },
      { text: "Цена", value: "base_price", class: "table-title" },
      { text: "Дата", value: "dateRange", class: "table-title" },
      { text: "Действие", value: "actions", class: "table-title" }
    ],
    tariffs: []
  }),
  created() {
    this.loadRow();
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  methods: {
    formatDate(date) {
      return moment(date * 1000).format("DD.MM.YYYY");
    },
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      let resp = await TariffsApiUrls.getAdditionals(params);
      this.totalItems = resp.meta.pagination.count;
      this.items = resp.results;
      this.loading = false;
    },
    createAddTariff() {
      this.$router.push("/additional-tariffs/add");
    },
    editItem(item) {
      this.$router.push(`/additional-tariffs/${item.id}`);
    },
    async deleteItem(item) {
      this.loading = true;
      await TariffsApiUrls.deleteAdditional(item.id);
      toast.success("Успешно удалено!");
      this.loadRow();
    }
  }
};
</script>

<style scoped></style>
